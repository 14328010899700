import { Outlet, Link,useLocation } from "react-router-dom";

export const Contact = ()=>{
  const location=useLocation()
 // alert(JSON.stringify(location.state===null))
  if(location.state){
  
  alert(location.state[0].name)
  }
    return (<>  
            <div style={{display:"inline-block",minHeight:"230vh",width:"100%"}}className="asidemain">

         <div className="nav">
        <ul>
          <li>
            <Link to="/home/">HOME</Link>
          </li>
          
          
          <li>
            <Link to="/HomeTest/">START TEST</Link>
          </li>
          <li className="active" >
          <Link to="/contact/">CONTACT</Link>
          </li>
          
          
        
          
         
        </ul>
      </div>

      <Outlet />
        <div style={{textAlign:"center",height:"230vh"}} className="header"> 
        <h3>WELCOME</h3>
        
        <p>E-MAIL US- </p> 
        <p style={{fontSize:"35px"}}>bj167745@gmail.com </p>
        
        <p>Contact-Us at <span style={{color:"red"}}>6378918603</span> </p>
        
        </div> 
      
         </div>
         </>
    )}
    
     
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
  
     